import React, { useMemo } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import "@cyntler/react-doc-viewer/dist/index.css";
import './CustomFileViewer.css';

function CustomFilesViewer({ fileurls, initialPage }) {
console.log(fileurls);

  function getFileExtension(url) {
    try {
      const pathname = new URL(url).pathname;
      return pathname.split('.').pop().toLowerCase();
    } catch (error) {
      console.error('Invalid URL:', url);
      return '';
    }
  }

  const docs = useMemo(() => fileurls.map(url => {
    const fileType = getFileExtension(url);
    let uri = url;
    if (fileType === 'pdf' && initialPage) {
      uri = `${url}#page=${initialPage}`;
    }
    return { uri, fileType };
  }), [fileurls, initialPage]);

  const config = useMemo(() => ({
    header: {
      disableHeader: true,
    },
    pdfZoom: {
      zoomJump: 0.2,
    },
    pdfVerticalScrollByDefault: true,
  }), []);

  return (
    <DocViewer
      documents={docs}
      prefetchMethod="GET"
      pluginRenderers={DocViewerRenderers}
      style={{ height: '100vh', width: '100%' }}
      config={config}
      onError={(e) => console.warn('Error in loading document', e)}
      onLoadError={(e) => console.warn('Error in loading document', e)}
      onDocumentLoad={(e) => console.log('Document loaded', e)}
    />
  );
}

export default CustomFilesViewer;