import React, { useState, useContext } from 'react';
import { Card, Table, Badge, Spinner, Modal } from 'react-bootstrap';
import LayoutPageHeader from '../../general/LayoutPageHeader';
import CustomFilesViewer from '../../general/CustomFilesViewer';
import GenericModal from '../../general/GenericModal';
import ProcedureList from './ProcedureList';
import ProcedureDetail from './ProcedureDetail';
import AuditOverview from './overview/AuditOverview';
import { DataRoomContext } from '../DisplayDataRoom';
import { useUser } from '../../general/UserProvider';

import { useWorkflow } from '../../hooks/workflow/useControls';

import './Workflow.css';

function Workflow() {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);    
    const [currentCycle, setCurrentCycle] = useState(null);
    const { workflowData,  loading: loadingControls, error: errorControls } = useWorkflow(token, dataroom_id);

    const [fileModalProperties, setFileModalProperties] = useState({
        isOpen: false,
        title: "",
        content: null
    });

    const [procedureModalProperties, setProcedureModalProperties] = useState({
        isOpen: false,
        title: "",
        procedures: []
    });

    const [procedureDetailModalProperties, setProcedureDetailModalProperties] = useState({
        isOpen: false,
        procedure: null
    });

    const handleFileOpen = (fileUrl) => {
        setFileModalProperties({
            isOpen: true,
            title: "File Viewer",
            content: <CustomFilesViewer fileurls={[fileUrl]} initialPage={1} />
        });
    };

    const closeFileModal = () => setFileModalProperties(prev => ({ ...prev, isOpen: false }));

    const handleProcedureModalOpen = (cycle) => {
        setCurrentCycle(cycle);
        setProcedureModalProperties({
            isOpen: true,
            title: `Controls for ${cycle.name}`,
            procedures: cycle.controls
        });
    };

    const closeProcedureModal = () => setProcedureModalProperties(prev => ({ ...prev, isOpen: false }));

    const handleProcedureDetailOpen = (procedure) => {
        setProcedureDetailModalProperties({
            isOpen: true,
            procedure: procedure
        });
    };

    const closeProcedureDetailModal = () => setProcedureDetailModalProperties(prev => ({ ...prev, isOpen: false }));

    const calculateProgress = (controls) => {
        if (!controls || controls.length === 0) return 0;
        const completedControls = controls.filter(control => control.status === "Completed").length;
        return completedControls / controls.length;
    };

    const renderCyclesTable = () => (
        <Card className="cycles-card mb-3">
            <Card.Body>
                <Card.Title className="cycles-title">
                    <i className="bi bi-diagram-3 me-2"></i>Audit Cycles
                </Card.Title>
                <Table hover responsive className="workflow-table">
                    <thead>
                        <tr>
                            <th>Cycle Name</th>
                            <th>Controls Count</th>
                            <th>Controls Progress</th>
                            <th>Risks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workflowData.map(cycle => {
                            const progress = calculateProgress(cycle.controls);
                            const hasRisks = cycle.cycle_risks && cycle.cycle_risks.length > 0;
                            
                            return (
                                <tr key={cycle.id} onClick={() => handleProcedureModalOpen(cycle)} className="cycle-row">
                                    <td>
                                        <i className="bi bi-folder2-open me-2"></i>
                                        {cycle.name}
                                    </td>
                                    <td>
                                        <i className="bi bi-list-check me-2"></i>
                                        {cycle.controls.length}
                                    </td>
                                    <td>
                                        <div className="progress">
                                            <div 
                                                className="progress-bar" 
                                                role="progressbar" 
                                                style={{width: `${progress * 100}%`}}
                                                aria-valuenow={progress * 100} 
                                                aria-valuemin="0" 
                                                aria-valuemax="100"
                                            >
                                                {Math.round(progress * 100)}%
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <Badge bg={hasRisks ? 'warning' : 'success'}>
                                            <i className={`bi ${hasRisks ? 'bi-exclamation-triangle' : 'bi-check'} me-1`}></i>
                                            {hasRisks ? `${cycle.cycle_risks.length} risks` : 'No risks'}
                                        </Badge>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );

    return (
        <LayoutPageHeader title="Audit Workflow">
            {loadingControls ? (
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : errorControls ? (
                <div className="alert alert-danger" role="alert">
                    <i className="bi bi-exclamation-triangle-fill me-2"></i>
                    {errorControls}
                </div>
            ) : (
                <>
                    <AuditOverview 
                        dataroomId={dataroom_id}
                        token={token}
                        workflowData={workflowData}
                        onFileOpen={handleFileOpen}
                    />
                    {renderCyclesTable()}
                </>
            )}

            <GenericModal 
                show={fileModalProperties.isOpen}
                onHide={closeFileModal}
                title={fileModalProperties.title}
                fullscreen={true}
                centered={false}
                no_padding={true}
            >
                {fileModalProperties.content}
            </GenericModal>

            <Modal 
                show={procedureModalProperties.isOpen} 
                onHide={closeProcedureModal}
                fullscreen
                className="procedure-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="bi bi-list-ul me-2"></i>
                        {procedureModalProperties.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProcedureList 
                        procedures={procedureModalProperties.procedures} 
                        onProcedureClick={handleProcedureDetailOpen}
                        cycleName={currentCycle ? currentCycle.name : ''}
                        supervisor={currentCycle?.cycle_supervisor_username || 'Not assigned'}
                        auditor={currentCycle?.cycle_auditor_username || 'Not assigned'}
                        risks={currentCycle?.cycle_risks || []}
                    />
                </Modal.Body>
            </Modal>

            <Modal 
                show={procedureDetailModalProperties.isOpen} 
                onHide={closeProcedureDetailModal}
                fullscreen
                className="procedure-detail-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="bi bi-clipboard-data me-2"></i>
                        Control Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {procedureDetailModalProperties.procedure && currentCycle && (
                        <ProcedureDetail 
                            procedure={procedureDetailModalProperties.procedure}
                            dataroomId={dataroom_id}
                            onFileOpen={handleFileOpen}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </LayoutPageHeader>
    );
}

export default Workflow;