import React from 'react';

const DocumentSection = ({ 
    title, 
    action,
    children 
}) => (
    <div className="dm-section">
        <div className="dm-header">
            <h6 className="dm-subtitle">{title}</h6>
            {action}
        </div>
        {children}
    </div>
);

export default DocumentSection;