import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export function useHandleIRStatusChange({ token, requests, setRequests }) {
    return async function handleStatusChange(item, newStatus) {
        try {
            const response = await api.put(API_ROUTES.UPDATE_IRL_STATUS(item.irl_id), {
                status: newStatus
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            
            if (response.status === 200) {
                const updatedRequests = requests.map(r =>
                    r.irl_id === item.irl_id ? { ...r, status: newStatus } : r
                );
                setRequests(updatedRequests);
            } else {
                console.error("Error updating status", response.data);
            }
        } catch (error) {
            console.error('Failed to update the status:', error);
        }
    };
}