import React, { useState, useRef, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import './controlCopilot.css';

const ControlCopilot = () => {
    const [userInput, setUserInput] = useState('');
    const messagesEndRef = useRef(null);
    const [chatHistory, setChatHistory] = useState([
        {
            type: 'assistant',
            content: `Based on my analysis of the provided documents for the "Review of Loans" control, here are my findings:
    
    1. Population Analysis:
    - Total number of loans reviewed: 128
    - Total value: €24.5M
    - Sample tested: 15 loans (12% of population)
    
    2. Documentation Review:
    - Loan agreements are properly executed for 14/15 samples
    - One loan agreement (#L789) is missing the final signature page
    - Interest rates match approved terms in all reviewed cases
    - Repayment schedules are properly documented
    
    3. Valuation Assessment:
    - 13 loans are performing according to schedule
    - 2 loans show late payments (30+ days):
      * Loan #L456: €180,000
      * Loan #L789: €250,000
    
    4. Key Findings:
    a) Missing Documentation:
       - Loan #L789: Missing signature page
    b) Potential Issues:
       - Two loans with late payments require additional monitoring
       - Combined exposure of late payments: €430,000 (1.8% of total portfolio)
    
    Would you like me to save these results to the control documentation?`
        },
        {
            type: 'user',
            content: "I think we should request additional documentation before concluding. The sample might not be sufficient given the identified issues. What other documents should we request?"
        },
        {
            type: 'assistant',
            content: `You raise a valid point. Given the identified issues, I recommend expanding our sample using a risk-based approach:
    
    Suggested Additional Documentation:
    
    1. Extended Sample Selection:
       - Focus on loans >€200,000 (higher risk threshold)
       - Additional 10 loans should be selected, prioritizing:
         * Loans with similar characteristics to #L456 and #L789
         * Loans issued in the same period
         * Loans managed by the same loan officers
    
    2. Specific Documents to Request:
       - Complete loan files including:
         * Original signed agreements
         * Latest bank valuations
         * Payment history for the last 12 months
         * Any loan restructuring documentation
         * Credit committee approvals
    
    3. Sampling Methodology:
       - Stratified sampling based on:
         * Loan size (focus on larger exposures)
         * Payment status (prioritize loans with any history of delays)
         * Loan age (focus on both oldest and newest loans)
       - This will increase our coverage to 20% of the total loan value
    
    Would you like me to prepare the document request for the client now?`
        }
    ]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!userInput.trim()) return;

        setChatHistory(prev => [...prev, {
            type: 'user',
            content: userInput
        }]);

        // Simulate AI response
        setChatHistory(prev => [...prev, {
            type: 'assistant',
            content: "I acknowledge your request. This is a placeholder response as the AI integration is still pending. Soon, I'll be able to provide meaningful assistance with your controls."
        }]);

        setUserInput('');
    };

    return (
        <div className="copilot-chat-container">
            <div className="chat-messages-area" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}>
                {chatHistory.map((message, index) => (
                    <div 
                        key={index} 
                        className={`chat-message d-flex ${message.type === 'user' ? 'justify-content-end' : 'justify-content-start'}`}
                    >
                        <div className={`message-bubble ${message.type === 'user' ? 'user-message' : 'assistant-message'}`}>
                            {message.type === 'assistant' && (
                                <div className="d-flex align-items-center mb-2">
                                    <i className="bi bi-robot me-2"></i>
                                    <strong>Control Copilot</strong>
                                </div>
                            )}
                            <div className="message-content">
                                {message.content}
                            </div>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>

            <div className="chat-input-area" style={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
                <Form onSubmit={handleSubmit}>
                    <div className="d-flex gap-2">
                        <Form.Control
                            as="textarea"
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            placeholder="Ask me anything about your control..."
                            style={{ resize: 'none', height: '60px' }}
                            className="flex-grow-1"
                        />
                        <Button 
                            type="submit" 
                            variant="primary"
                            disabled={!userInput.trim()}
                            className="d-flex align-items-center px-4"
                        >
                            <i className="bi bi-send me-2"></i>
                            Send
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default ControlCopilot;