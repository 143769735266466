import React, { useMemo } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import './CustomFileViewer.css';

function CustomFileViewer({ fileurl }) {
  function getFileExtension(url) {
    try {
      const pathname = new URL(url).pathname;
      return pathname.split('.').pop().toLowerCase();
    } catch (error) {
      console.error('Invalid URL:', url);
      return '';
    }
  }

  const extension = getFileExtension(fileurl);
  const docs = useMemo(() => [{ uri: fileurl, fileType: extension }], [fileurl, extension]);

  const config = useMemo(() => ({
    header: {
      disableHeader: true,
    },
    pdfZoom: {
      zoomJump: 0.2,
    },
    pdfVerticalScrollByDefault: true,
  }), []);

  return (
    <DocViewer
      documents={docs}
      prefetchMethod="GET"
      pluginRenderers={DocViewerRenderers}
      style={{ height: '100vh', width: '100%' }}
      config={config}
      onError={(e) => console.warn('Error in loading document', e)}
      onLoadError={(e) => console.warn('Error in loading document', e)}
      onDocumentLoad={(e) => console.log('Document loaded', e)}
    />
  );
}

export default CustomFileViewer;