import { useState, useCallback, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useWorkflow = (token, dataroomId) => {
    const [workflowData, setWorkflowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchWorkflow = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await api.get(
                API_ROUTES.GET_WORKFLOW(dataroomId),
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            setWorkflowData(response.data);
            setLoading(false);
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while fetching the workflow');
            setLoading(false);
        }
    }, [token, dataroomId]);

    useEffect(() => {
        fetchWorkflow();
    }, [fetchWorkflow]);

    return { workflowData, loading, error, refetch: fetchWorkflow };
};

export const useControlUpdates = (token, dataroomId) => {
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const updatePriorityDescription = async (controlId, description) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            await api.put(
                API_ROUTES.UPDATE_PRIORITY_DESCRIPTION(dataroomId, controlId),
                { priority_description: description },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            setUpdating(false);
            return true;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the priority description');
            setUpdating(false);
            return false;
        }
    };

    const updatePriority = async (controlId, priority) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            await api.put(
                API_ROUTES.UPDATE_PRIORITY(dataroomId, controlId),
                { priority },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            setUpdating(false);
            return true;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the priority');
            setUpdating(false);
            return false;
        }
    };

    const updateStatus = async (controlId, status) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            await api.put(
                API_ROUTES.UPDATE_STATUS(dataroomId, controlId),
                { status },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            setUpdating(false);
            return true;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the status');
            setUpdating(false);
            return false;
        }
    };

    const updateName = async (controlId, name) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            await api.put(
                API_ROUTES.UPDATE_NAME(dataroomId, controlId),
                { name },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            setUpdating(false);
            return true;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the name');
            setUpdating(false);
            return false;
        }
    };

    const updateDescription = async (controlId, description) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            await api.put(
                API_ROUTES.UPDATE_DESCRIPTION(dataroomId, controlId),
                { description },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            setUpdating(false);
            return true;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the description');
            setUpdating(false);
            return false;
        }
    };

    const updateSteps = async (controlId, steps) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            await api.put(
                API_ROUTES.UPDATE_STEPS(dataroomId, controlId),
                { steps },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            setUpdating(false);
            return true;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the steps');
            setUpdating(false);
            return false;
        }
    };

    const updateResults = async (controlId, results) => {
        setUpdating(true);
        setUpdateError(null);

        try {
            await api.put(
                API_ROUTES.UPDATE_RESULTS(dataroomId, controlId),
                { results },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            setUpdating(false);
            return true;
        } catch (error) {
            setUpdateError(error.response?.data?.message || 'An error occurred while updating the results');
            setUpdating(false);
            return false;
        }
    };

    return { 
        updatePriorityDescription, 
        updatePriority,
        updateStatus,
        updateName,
        updateDescription,
        updateSteps,
        updateResults,
        updating, 
        updateError 
    };
};