import React from 'react';
import DocumentItem from './DocumentItem';
import DocumentSection from './DocumentSection';

const AssociatedDocuments = ({
    documents,
    onFileOpen,
    onDeleteFile,
    controlId
}) => (
    <DocumentSection title="Associated Documents">
        {documents.length > 0 ? (
            <div className="dm-list">
                {documents.map((fileLink) => (
                    <DocumentItem
                        key={fileLink.id}
                        name={fileLink.file.name}
                        icon="bi-file-earmark-check"
                        onClick={() => onFileOpen(fileLink.file)}
                        metadata={
                            fileLink.is_requested && (
                                <>Originally requested by: {fileLink.requested_by_username || 'Unknown'}</>
                            )
                        }
                        actions={
                            <i 
                                className="bi bi-x-lg dm-action-btn dm-action-btn--delete"
                                onClick={() => onDeleteFile(controlId, fileLink.id)}
                            ></i>
                        }
                    />
                ))}
            </div>
        ) : (
            <p className="dm-empty">No associated documents.</p>
        )}
    </DocumentSection>
);

export default AssociatedDocuments;