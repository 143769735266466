import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import StatusDropdown from '../StatusDropdown';
import RowActions from './RowActions';
import { formatDate } from '../../../utils/Date';
import { TEAM_URL } from '../../../utils/urls';

function IRRow({
  item,
  handleStatusChange,
  statuses,
  statusLoading,
  statusError,
  setModalState,
  closeModal,
  submitDelete,
  handleUpdateRequest,
  dataroom_id,
  onRowClick
}) {
    const folderName = item.folder_name; 
    const folderHierarchyTooltip = (item.folder_hierarchy.length > 0 
        ? item.folder_hierarchy.join(' > ') + ' > '
        : '') + folderName;

    const renderTooltip = props => (
        <Tooltip {...props}>{folderHierarchyTooltip}</Tooltip>
    );

    return (
        <tr className="irl-row">
            <td className="irl-cell folder-cell">
                <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip}
                >
                    <div className="folder-name">{folderName}</div>
                </OverlayTrigger>
            </td>
            <td className="irl-cell">
                <Link 
                    to="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        onRowClick(item);
                    }}
                    className="ir-title-link"
                    title="Click to see details"
                >
                    {item.title}
                </Link>
            </td>
            <td className="irl-cell text-center">
                <StatusDropdown
                    item={item}
                    handleStatusChange={handleStatusChange}
                    statuses={statuses}
                    statusLoading={statusLoading}
                    statusError={statusError}
                />
            </td>
            <td className="irl-cell text-center">
                <Link to={TEAM_URL.replace(':dataroomId', dataroom_id).replace(':teamId', item.created_by)}>
                    {item.creator}
                </Link>
            </td>
            <td className="irl-cell text-center">
                <Link to={TEAM_URL.replace(':dataroomId', dataroom_id).replace(':teamId', item.assignee)}>
                    {item.assignee_name}
                </Link>
            </td>
            <td className="irl-cell text-center">{formatDate(item.deadline)}</td>
            <td className="irl-cell">
                <RowActions
                    item={item}
                    setModalState={setModalState}
                    closeModal={closeModal}
                    submitDelete={submitDelete}
                    handleUpdateRequest={handleUpdateRequest}
                />
            </td>
        </tr>
    );
}

export default IRRow;