import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './ProcedureDetailsLeft.css';

function ProcedureDetailsLeft({ 
    procedure, 
    onEditResult,
    onUpdateName,
    onUpdateDescription,
    onUpdateSteps,
    onUpdateResults,
    onCopilotClick,
    updating
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [editingField, setEditingField] = useState(null);
    const [editedResults, setEditedResults] = useState(procedure.results || '');
    const [editedName, setEditedName] = useState(procedure.name);
    const [editedDescription, setEditedDescription] = useState(procedure.description);
    const [editedSteps, setEditedSteps] = useState(procedure.steps);

    const startEditing = (field) => {
        setEditingField(field);
        setIsEditing(true);
    };

    const cancelEditing = () => {
        setEditingField(null);
        setIsEditing(false);
        setEditedName(procedure.name);
        setEditedDescription(procedure.description);
        setEditedSteps(procedure.steps);
    };

    const handleSave = async () => {
        let success = false;
    
        switch(editingField) {
            case 'name':
                success = await onUpdateName(editedName);
                break;
            case 'description':
                success = await onUpdateDescription(editedDescription);
                break;
            case 'steps':
                success = await onUpdateSteps(editedSteps);
                break;
            case 'results':
                success = await onUpdateResults(editedResults);
                break;
            default:
                success = false;
                break;
        }
    
        if (success) {
            setIsEditing(false);
            setEditingField(null);
        }
    };

    const handleStepChange = (index, value) => {
        const newSteps = [...editedSteps];
        newSteps[index] = value;
        setEditedSteps(newSteps);
    };

    const handleCopyResults = () => {
        navigator.clipboard.writeText(procedure.results || 'No results available yet.')
            .then(() => {
                alert('Results copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy results: ', err);
                alert('Failed to copy results. Please try again.');
            });
    };

    const renderEditableField = () => {
        switch(editingField) {
            case 'name':
                return (
                    <Form.Control
                        type="text"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        className="mb-2"
                    />
                );
            case 'description':
                return (
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                        className="mb-2"
                    />
                );
            case 'steps':
                return (
                    <div className="mb-2">
                        {editedSteps.map((step, index) => (
                            <Form.Control
                                key={index}
                                type="text"
                                value={step}
                                onChange={(e) => handleStepChange(index, e.target.value)}
                                className="mb-2"
                            />
                        ))}
                    </div>
                );
            case 'results':
                return (
                    <Form.Control
                        as="textarea"
                        rows={5}
                        value={editedResults}
                        onChange={(e) => setEditedResults(e.target.value)}
                        className="result-textarea"
                        disabled={procedure.status === 'Completed'}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="procedure-details-left">
            <div className="procedure-header d-flex justify-content-between align-items-start">
                <div>
                    <div className="d-flex align-items-center">
                        {isEditing && editingField === 'name' ? (
                            <div className="edit-container w-100">
                                {renderEditableField()}
                                <div className="edit-actions">
                                    <Button 
                                        variant="primary" 
                                        onClick={handleSave} 
                                        className="me-2"
                                        disabled={updating}
                                        size="sm"
                                    >
                                        {updating ? 'Saving...' : 'Save'}
                                    </Button>
                                    <Button 
                                        variant="secondary" 
                                        onClick={cancelEditing}
                                        disabled={updating}
                                        size="sm"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <h1 className="procedure-title mb-1">{procedure.name}</h1>
                                <Button 
                                    variant="link" 
                                    onClick={() => startEditing('name')}
                                    className="edit-button"
                                    disabled={updating}
                                >
                                    <i className="bi bi-pencil edit-icon"></i>
                                </Button>
                            </>
                        )}
                    </div>
                    <div className="assertions-container">
                        {procedure.assertions && procedure.assertions.map((assertion) => (
                            <span key={assertion.id} className="assertion-badge">
                                <i className="bi bi-tag"></i>
                                {assertion.title}
                            </span>
                        ))}
                    </div>
                </div>
                <Button 
                    variant="link" 
                    onClick={onCopilotClick} 
                    className="chat-button p-0"
                    style={{ color: 'black' }}
                >
                    <i className="bi bi-robot fs-4"></i>
                </Button>
            </div>

            <div className="procedure-content">
            <section className="description-section">
                <div className="d-flex align-items-center">
                    <h2 className="section-title-left mb-0">Description</h2>
                    <Button 
                        variant="link" 
                        onClick={() => startEditing('description')}
                        className="edit-button p-0 d-flex align-items-center"
                        disabled={updating}
                    >
                        <i className="bi bi-pencil edit-icon"></i>
                    </Button>
                </div>
                {isEditing && editingField === 'description' ? (
                    <div className="edit-container w-100">
                        {renderEditableField()}
                        <div className="edit-actions">
                            <Button 
                                variant="primary" 
                                onClick={handleSave} 
                                className="me-2"
                                disabled={updating}
                                size="sm"
                            >
                                {updating ? 'Saving...' : 'Save'}
                            </Button>
                            <Button 
                                variant="secondary" 
                                onClick={cancelEditing}
                                disabled={updating}
                                size="sm"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                ) : (
                    <p>{procedure.description}</p>
                )}
            </section>

            <section className="steps-section">
                <div className="d-flex align-items-center gap-1">
                    <h2 className="section-title-left mb-0">Steps</h2>
                    <Button 
                        variant="link" 
                        onClick={() => startEditing('steps')}
                        className="edit-button p-0 d-flex align-items-center"
                        disabled={updating}
                    >
                        <i className="bi bi-pencil edit-icon"></i>
                    </Button>
                </div>
                {isEditing && editingField === 'steps' ? (
                    <div className="edit-container w-100">
                        {renderEditableField()}
                        <div className="edit-actions">
                            <Button 
                                variant="primary" 
                                onClick={handleSave} 
                                className="me-2"
                                disabled={updating}
                                size="sm"
                            >
                                {updating ? 'Saving...' : 'Save'}
                            </Button>
                            <Button 
                                variant="secondary" 
                                onClick={cancelEditing}
                                disabled={updating}
                                size="sm"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                ) : (
                    <ol className="step-list">
                        {procedure.steps.map((step, index) => (
                            <li key={index} className="step-item">
                                {step}
                            </li>
                        ))}
                    </ol>
                )}
            </section>

                {procedure.assertionTypes && procedure.assertionTypes.length > 0 && (
                    <section className="assertions-section">
                        {procedure.assertionTypes.map((assertion, index) => (
                            <span className="assertion-item" key={index}>
                                <i className="bi bi-tag"></i>  <i>{assertion}</i>{index < procedure.assertionTypes.length - 1 ? ' ' : ''}
                            </span>
                        ))}
                    </section>
                )}
            </div>

            {procedure.sampling && procedure.sampling.applicable && (
                <section className="sampling-section mb-5">
                    <h2 className="section-title-left">Sampling Information</h2>
                    <p><strong>Method:</strong> {procedure.sampling.method}</p>
                    {procedure.sampling.sampleSize && (
                        <p><strong>Sample Size:</strong> {procedure.sampling.sampleSize}</p>
                    )}
                    {procedure.sampling.selectionCriteria && (
                        <p><strong>Selection Criteria:</strong> {procedure.sampling.selectionCriteria}</p>
                    )}
                </section>
            )}

            <section className="results-section">
                <h2 className="section-title-results">Results</h2>
                {isEditing && editingField === 'results' ? (
                <Form>
                    {renderEditableField()}
                    <div className="mt-3">
                        <Button 
                            variant="primary" 
                            onClick={handleSave} 
                            className="me-2"
                            disabled={procedure.status === 'Completed' || updating}
                        >
                            {updating ? 'Saving...' : 'Save'}
                        </Button>
                        <Button 
                            variant="secondary" 
                            onClick={cancelEditing}
                            disabled={procedure.status === 'Completed' || updating}
                        >
                            Cancel
                        </Button>
                    </div>
                </Form>
            ) : (
                <>
                    <p className={procedure.status === 'Completed' ? 'text-muted' : ''}>
                        {procedure.results || 'No results available yet.'}
                    </p>
                    <div className="d-flex">
                        <Button 
                            variant="outline-primary" 
                            onClick={() => startEditing('results')}
                            disabled={procedure.status === 'Completed'}
                            className="me-2"
                        >
                            <i className="bi bi-pencil me-2"></i>
                            Edit
                        </Button>
                        <Button 
                            variant="outline-secondary" 
                            onClick={handleCopyResults}
                        >
                            <i className="bi bi-clipboard me-2"></i>
                            Copy
                        </Button>
                    </div>
                </>
                )}
            </section>
        </div>
    );
}

export default ProcedureDetailsLeft;