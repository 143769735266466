import React, { useMemo, useCallback, useContext } from 'react';
import { useUser } from '../../general/UserProvider';

import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import { DataRoomContext } from '../DisplayDataRoom';
import FolderSelector from '../../common/FolderSelector';

import { useFetchIRStatuses } from '../../hooks/ir/useFetchIRStatuses';
import { useIRFormData } from '../../hooks/ir/useIRFormData';
import { useIRFormValidation } from '../../hooks/ir/useIRFormValidation';
import { useFolders } from '../../hooks/documents/useFolders';
import { useDataroomUsers } from '../../hooks/users/useDataroomUsers';

import { findFolderById } from '../../utils/Folders';

function NewRequestForm({ onSubmit, item }) {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);

    const { statuses, loading: statusLoading, error: statusError } = useFetchIRStatuses(token);
    const { folders, loading: foldersLoading, error: foldersError } = useFolders(token, dataroom_id, true, null, 'ir', 'Create');
    const {dataroomUsers, errorDataroomUsers} = useDataroomUsers(token, dataroom_id);
    const initialFormState = useMemo(() => ({
        title: item?.title || '',
        description: item?.description || '',
        deadline: item ? new Date(item.deadline).toISOString().split('T')[0] : '',
        status_id: item?.status_id || '',
        folder_id: item?.folder_id || (folders.length > 0 ? folders[0].id : ''),
        folder_name: item?.folder_name || '', 
        folder_hierarchy: item?.folder_hierarchy || [],
        assignee: item?.assignee || ''
    }), [item, folders]);
    const [formData, handleInputChange, resetFormData] = useIRFormData(initialFormState);

    const validate = useCallback((formData) => {
        const errors = {};
        if (!formData.title.trim()) errors.title = 'Title is required';
        if (!formData.description.trim()) errors.description = 'Description is required';
        if (!formData.deadline.trim()) errors.deadline = 'Deadline is required';
        return errors;
    }, []);
      
    const submitForm = useCallback(() => {
        const folderResult = findFolderById(folders, formData.folder_id);

        if (folderResult) {
            const selectedFolder = folderResult.selectedFolder;
            const folderHierarchy = folderResult.folderHierarchy;
            onSubmit({
                ...formData,
                status_id: formData.status_id ? parseInt(formData.status_id, 10) : null,
                folder_name: selectedFolder?.name || formData.folder_name,
                folder_hierarchy: folderHierarchy || formData.folder_hierarchy
            });
            resetFormData(initialFormState);
        } else {
            console.log("Folder not found");
        }
        
    }, [formData, onSubmit, resetFormData, initialFormState, folders]);

    const { handleSubmit, errors, isSubmitting } = useIRFormValidation(formData, validate, submitForm);

    const loadingIndicator = useMemo(() => (
        statusLoading && <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ), [statusLoading]);
    
      const errorAlert = useMemo(() => (
        statusError && <Alert variant="danger">{statusError}</Alert>
      ), [statusError]);

      const foldersErrorAlert = useMemo(() => (
        foldersError && <Alert variant="danger">{foldersError.message}</Alert>
    ), [foldersError]);

    const foldersLoadingIndicator = useMemo(() => (
        foldersLoading && <Spinner animation="border" role="status">
            <span className="visually-hidden">Folders are loading...</span>
        </Spinner>
    ), [foldersLoading]);

    const dataroomUsersErrorAlert = useMemo(() => (
        errorDataroomUsers && <Alert variant="danger">{errorDataroomUsers}</Alert>
    ), [errorDataroomUsers]);

    return (
        <Form onSubmit={handleSubmit}>
            {loadingIndicator}
            {foldersLoadingIndicator}
            {errorAlert}
            {foldersErrorAlert}
            {dataroomUsersErrorAlert}

            <FolderSelector 
                folders={folders} 
                value={formData.folder_id} 
                handleInputChange={handleInputChange} 
                displayLabel={true}
            />

            <Form.Group className="text-black pt-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    value={formData.title}
                    onChange={handleInputChange("title")}
                    name="title"
                    placeholder="Enter a title for your request"
                />
                {errors.title && <Form.Text className="text-danger">{errors.title}</Form.Text>}
            </Form.Group>
            
            <Form.Group className="text-black pt-3">
                <Form.Label>Describe your request</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={formData.description}
                    onChange={handleInputChange("description")} 
                    name="description" 
                    placeholder="Ex: Provide balance sheets, income statements, and cash flow statements for the past 3 years."
                />
            </Form.Group>

            {item && (
                <Form.Group className="text-black pt-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" value={formData.status_id || ''} onChange={handleInputChange("status_id")} name="status_id">
                        <option value="" disabled>Select a status</option>
                        {
                            statuses.map(statusItem => (
                                <option key={statusItem.status_id} value={statusItem.status_id}>
                                    {statusItem.name}
                                </option>
                            ))
                        }
                    </Form.Control>
                    {statusLoading && <div>Loading statuses...</div>}
                    {statusError && <Alert variant="danger">{statusError}</Alert>}
                </Form.Group>
            )}

            <Form.Group className="text-black pt-3">
                <Form.Label>Deadline</Form.Label>
                <Form.Control
                    type="date"
                    value={formData.deadline}
                    onChange={handleInputChange("deadline")}
                    name="deadline"
                />
            </Form.Group>

            <Form.Group className="text-black pt-3">
                <Form.Label>Assignee</Form.Label>
                <Form.Control as="select" value={formData.assignee || ''} onChange={handleInputChange("assignee")} name="assignee">
                    <option value="">No assignee</option>
                    {dataroomUsers.map(user => (
                        <option key={user.user_id} value={user.user_id}>
                            {user.username}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>

            <Button variant="dark" type="submit" className="mt-3" disabled={Object.keys(errors).length > 0 && isSubmitting}>
                <i className="bi bi-file-earmark-plus icon-spacing"></i> 
                {item ? 'Update information request' : 'Create information request'}
            </Button>
        </Form>
    );
};

export default NewRequestForm;
