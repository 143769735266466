import { useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useUpdateInformationRequest = (token, requests, setRequests) => {
    const updateRequest = useCallback(async (irl_id, updateData) => {
        try {
            const response = await api.put(API_ROUTES.UPDATE_IRL(irl_id), updateData, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status === 200) {
                const updatedIRL = response.data;
                setRequests(prevRequests => prevRequests.map(request => {
                    return request.irl_id === irl_id ? { ...request, ...updatedIRL } : request;
                }));
            } else {
                console.error("Error updating request - Status:", response.status, "Data:", response.data);
            }
        } catch (error) {
            console.error('There was a problem updating the request:', error);
        }
    }, [token, setRequests]);

    return updateRequest;
};