const getDataroomCategoriesURL = (dataroom_id, feature, permission) => {
    return `/datarooms/${dataroom_id}/categories/${feature}/${permission}`;
};

const getDataroomFoldersURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}/folders`;
};

const getNotesURL = (dataroom_id) => {
    return `/get_notes/${dataroom_id}`;
};

const getNoteByIDURL = (dataroom_id, note_id) => {
    return `/dataroom/${dataroom_id}/get_note/${note_id}`;
};

const updateNoteByIDURL = (note_id) => {
    return `/update_note/${note_id}`;
};

const getUsersByDataroomURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}/users`;
};

const getPermissionsByDataroomURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}/permissions`;
};

const getPermissionsNamesByDataroomURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}/permission-names`;
};

const fetchGroupUsersURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}/grouped-users`;
};

const groupsWithUsersURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}/groups-with-users`;
};

const usersWithNoGroupsByDataroomURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}/users_without_group`;
};

const updateIRLStatusURL = (IRL_id) => {
    return `/irls/${IRL_id}/status`;
};

const updateIRLURL = (IRL_id) => {
    return `/irls/${IRL_id}`;
};

const deleteIRLURL = (IRL_id) => {
    return `/irls/${IRL_id}`;
};

const getIRLByDataroomURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}/irls`;
};

const deleteCommentURL = (comment_id) => {
    return `/comments/${comment_id}`;
};

const getDataroomByIDURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}`;
};

const updateDataroomURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}`;
};

const getSocialByDataroomURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}/social`;
};

const createNoteInDataroomURL = (dataroom_id) => {
    return `/add_note/${dataroom_id}`;
};

const deleteNoteFromDataroomURL = (note_id) => {
    return `/remove_note/${note_id}`;
};

const add_user_to_dataroom_default_group = (dataroom_id, user_id) => {
    return `/datarooms/${dataroom_id}/users/${user_id}/default_group`;
};

const remove_user_from_dataroom_groups = (dataroom_id, user_id) => {
    return `/datarooms/${dataroom_id}/users/${user_id}/groups`;
};

const getEventsURL = (dataroom_id) => {
    return `/datarooms/${dataroom_id}/events`;
};

const getDataroomUserURL = (dataroom_id, user_id) => {
    return `/datarooms/${dataroom_id}/users/${user_id}`;
};

const getObjectEventsURL = (dataroom_id, object_type, object_id) => {
    return `/datarooms/${dataroom_id}/object_type/${object_type}/object_id/${object_id}`;
};

const getNoteByFolderId = (dataroom_id, folder_id) => {
    return `/datarooms/${dataroom_id}/folder/${folder_id}/notes`;
};

const getDataroomGroupsURL = (dataroom_id) => {
    return `/admin/datarooms/${dataroom_id}/groups`;
};

const createDataroomGroupURL = (dataroom_id) => {
    return `/admin/datarooms/${dataroom_id}/groups`;
};

const updateDataroomGroupURL = (dataroom_id, group_id) => {
    return `/admin/datarooms/${dataroom_id}/groups/${group_id}`;
};

const deleteDataroomGroupURL = (dataroom_id, group_id) => {
    return `/admin/datarooms/${dataroom_id}/groups/${group_id}`;
};

const getDataroomGroupURL = (dataroom_id, group_id) => {
    return `/admin/datarooms/${dataroom_id}/groups/${group_id}`;
};

const getDataroomControlsURL = (dataroom_id) => {
    return `/admin/datarooms/${dataroom_id}/controls`;
};

export const API_ROUTES = {
    NOTE_COMMENT_COUNT: "/comments/count",
    LIST_DATAROOMS: "/datarooms",
    SEND_MAIL: "/emails",
    DATAROOM_CATEGORIES: getDataroomCategoriesURL,
    DATAROOM_FOLDERS: getDataroomFoldersURL,
    NOTES: getNotesURL,
    NOTE_BY_ID: getNoteByIDURL,
    GET_ALL_NOTES: (dataroom_id) => `/get_notes/${dataroom_id}`,
    GET_NOTES_BY_FOLDER: (dataroom_id, folder_id) => `/datarooms/${dataroom_id}/folder/${folder_id}/notes`,
    GET_ALL_NOTES_WITH_FOLDERS: (dataroom_id) => `/get_all_notes_with_folders/${dataroom_id}`,
    UPDATE_NOTE_BY_ID: updateNoteByIDURL,
    USERS_BY_DATAROOM: getUsersByDataroomURL,
    REMOVE_USER_FROM_GROUPS: remove_user_from_dataroom_groups,
    PERMISSIONS_BY_DATAROOM: getPermissionsByDataroomURL,
    PERMISSIONS_NAMES_BY_DATAROOM: getPermissionsNamesByDataroomURL,
    UPDATE_PERMISSION: "/permissions",
    FETCH_GROUP_USERS: fetchGroupUsersURL,
    GROUPS_WITH_USERS: groupsWithUsersURL,
    UPDATE_USER_GROUP: "/user-groups",
    USERS_WITH_NO_GROUPS_BY_DATAROOM: usersWithNoGroupsByDataroomURL,
    ADD_USER_TO_DEFAULT_GROUP: add_user_to_dataroom_default_group,
    GET_STATUSES: "/statuses",
    UPDATE_IRL_STATUS: updateIRLStatusURL,
    UPDATE_IRL: updateIRLURL,
    DELETE_IRL: deleteIRLURL,
    GET_IRL_BY_DATAROOM: getIRLByDataroomURL,
    CREATE_IRL: "/irls",
    GET_COMMENTS: "/comments",
    CREATE_COMMENT: "/comments",
    DELETE_COMMENT: deleteCommentURL,
    GET_DATAROOM_BY_ID: getDataroomByIDURL,
    UPDATE_DATAROOM: updateDataroomURL,
    SOCIAL_BY_DATAROOM: getSocialByDataroomURL,
    CREATE_NOTE_IN_DATAROOM: createNoteInDataroomURL,
    LINK_NOTE_TO_FOLDER: "/link_note_to_folder",
    DELETE_NOTE_FROM_DATAROOM: deleteNoteFromDataroomURL,
    EVENTS: getEventsURL,
    DATAROOM_USER: getDataroomUserURL,
    OBJECT_EVENTS: getObjectEventsURL,
    CREATE_FOLDER: "/folders",
    UPDATE_FOLDER: (folderId) => `/folders/${folderId}`,    
    DELETE_FOLDER: (folderId) => `/folders/${folderId}`,   
    UPLOAD_FILES: (dataroomId) => `/dataroom/${dataroomId}/upload`, 
    PROCESS_FILE: (dataroomId, fileId) => `/dataroom/${dataroomId}/process/${fileId}`,
    LIST_FILES: (folderId) => `/folders/${folderId}/files`,
    DELETE_FILE: (file_id) => `/files/${file_id}`,
    UPDATE_FILE: (file_id) => `/files/${file_id}`,
    NOTE_BY_FOLDER_ID: getNoteByFolderId,
    CREATE_DATAROOM: "/datarooms",
    PERMISSIONS_BY_USER_AND_DATAROOM: (dataroomId) => `/users/datarooms/${dataroomId}/permissions`,
    UPDATE_USER_QUOTA: (dataroomId, userId) => `/dataroom/${dataroomId}/user/${userId}/quota`,
    UPDATE_USER_INFO: (dataroomId, userId) => `/dataroom/${dataroomId}/user/${userId}`,
    ROLES_BY_DATAROOM: (dataroomId) => `/datarooms/${dataroomId}/roles`,
    GET_DISCUSSION_BY_SESSION_ID: (sessionId, dataroomId) => `/dataroom/${dataroomId}/sessions/${sessionId}/discussion`,
    GET_QUOTA_REMAINING: (dataroomId) => `/datarooms/${dataroomId}/quota-remaining`,
    GET_FOLDER_PARENTS: (dataroomId, folderId) => `/datarooms/${dataroomId}/folders/${folderId}/parents`,
    GET_FILE_BY_UUID: (dataroomId, uuid) => `/datarooms/${dataroomId}/files/uuid/${uuid}`,
    GET_CONTROL_POINTS_CATEGORY: (dataroomId) => `/datarooms/${dataroomId}/control-points/categories`,
    GET_CONTROL_POINTS_BY_CATEGORY: (dataroomId) => `/datarooms/${dataroomId}/control-points`,
    GET_CONTROL_POINTS_ALL: (dataroomId) => `/datarooms/${dataroomId}/all-control-points`,

    ADMIN_GET_USERS: '/admin/users',
    ADMIN_UPDATE_USER: (userId) => `/admin/users/${userId}`,
    ADMIN_ADD_USER: '/admin/users',
    ADMIN_DEACTIVATE_USER: (userId) => `/admin/users/${userId}/deactivate`,
    ADMIN_ACTIVATE_USER: (userId) => `/admin/users/${userId}/activate`,

    ADMIN_GET_DATAROOMS: '/admin/datarooms',
    ADMIN_ADD_DATAROOM: '/admin/datarooms',
    ADMIN_UPDATE_DATAROOM: (dataroomId) => `/admin/datarooms/${dataroomId}`,
    ADMIN_DEACTIVATE_DATAROOM: (dataroomId) => `/admin/datarooms/${dataroomId}/deactivate`,
    ADMIN_ACTIVATE_DATAROOM: (dataroomId) => `/admin/datarooms/${dataroomId}/activate`,

    ADMIN_GET_ROLES: '/admin/roles',
    ADMIN_GET_DATAROOM_ROLES: '/admin/datarooms',
    ADMIN_ADD_DATAROOM_ROLE: '/admin/datarooms',
    ADMIN_REMOVE_DATAROOM_ROLE: '/admin/datarooms',
    ADMIN_GET_DATAROOM_USERS_AND_ROLES: '/admin/datarooms',

    DATAROOM_GROUPS: getDataroomGroupsURL,
    CREATE_DATAROOM_GROUP: createDataroomGroupURL,
    UPDATE_DATAROOM_GROUP: updateDataroomGroupURL,
    DELETE_DATAROOM_GROUP: deleteDataroomGroupURL,
    GET_DATAROOM_GROUP: getDataroomGroupURL,
    ADMIN_DATAROOM_FOLDERS: (dataroomId) => `/admin/datarooms/${dataroomId}/folders`,
    ADMIN_GET_DOCUMENT_ATTRIBUTES: '/admin/document-attributes',
    ADMIN_GET_DOCUMENT_TYPES: '/admin/document-types',
    ADMIN_GET_ATTRIBUTES: '/admin/document-attributes',
    ADMIN_ADD_ATTRIBUTE: '/admin/document-attributes',
    ADMIN_UPDATE_ATTRIBUTE: '/admin/document-attributes', 
    ADMIN_DELETE_ATTRIBUTE: '/admin/document-attributes',
    ADMIN_DELETE_DOCUMENT_TYPE: '/admin/document-types',
    ADMIN_UPDATE_DOCUMENT_TYPE: '/admin/document-types',
    ADMIN_CREATE_DOCUMENT_TYPE: '/admin/document-types',
    ADMIN_GET_CONTROL_POINTS: '/admin/control-points',
    ADMIN_UPDATE_CONTROL_POINT: '/admin/control-points',
    ADMIN_ADD_CONTROL_POINT: '/admin/control-points',
    ADMIN_DELETE_CONTROL_POINT: '/admin/control-points',
    ADMIN_GET_CONTROL_POINT_CATEGORIES: 'admin/control-point-categories',

    ADMIN_ADD_ASSERTIONS: '/admin/assertions',
    ADMIN_UPDATE_ASSERTIONS: '/admin/assertions',
    ADMIN_DELETE_ASSERTIONS: '/admin/assertions',
    ADMIN_GET_ASSERTIONS: '/admin/assertions',

    ADMIN_GET_CYCLES: '/admin/cycles',
    ADMIN_ADD_CYCLES: '/admin/cycles',
    ADMIN_UPDATE_CYCLES: '/admin/cycles',
    ADMIN_DELETE_CYCLES: '/admin/cycles',
    ADMIN_REORDER_CYCLES: '/admin/cycles/reorder',

    ADMIN_GET_CONTROLS: '/admin/controls',
    ADMIN_ADD_CONTROLS: '/admin/controls',
    ADMIN_UPDATE_CONTROLS: '/admin/controls',
    ADMIN_DELETE_CONTROLS: '/admin/controls',
    ADMIN_REORDER_CONTROLS: '/admin/cycles/:cycleId/controls/reorder',

    ADMIN_GET_FOLDERS: '/admin/folders',
    ADMIN_ADD_FOLDERS: '/admin/folders',
    ADMIN_UPDATE_FOLDERS: '/admin/folders',
    ADMIN_DELETE_FOLDERS: '/admin/folders',
    ADMIN_REORDER_FOLDERS: '/admin/folders/reorder',

    ADMIN_DATAROOM_CONTROLS: getDataroomControlsURL,
    ADMIN_ADD_DATAROOM_CONTROL: getDataroomControlsURL,
    ADMIN_UPDATE_DATAROOM_CONTROL: (dataroom_id, control_id) => `/admin/datarooms/${dataroom_id}/controls/${control_id}`,
    ADMIN_DELETE_DATAROOM_CONTROL: (dataroom_id, control_id) => `/admin/datarooms/${dataroom_id}/controls/${control_id}`,
    ADMIN_INITIALIZE_DATAROOM_CONTROLS: (dataroom_id) => `/admin/datarooms/${dataroom_id}/initialize-controls`,

    ADMIN_DATAROOM_CYCLES: (dataroom_id) => `/admin/datarooms/${dataroom_id}/cycles`,
    ADMIN_ADD_DATAROOM_CYCLE: (dataroom_id) => `/admin/datarooms/${dataroom_id}/cycles`,
    ADMIN_UPDATE_DATAROOM_CYCLE: (dataroom_id, cycle_id) => `/admin/datarooms/${dataroom_id}/cycles/${cycle_id}`,
    ADMIN_DELETE_DATAROOM_CYCLE: (dataroom_id, cycle_id) => `/admin/datarooms/${dataroom_id}/cycles/${cycle_id}`,
    ADMIN_INITIALIZE_DATAROOM_CYCLES: (dataroom_id) => `/admin/datarooms/${dataroom_id}/initialize-cycles`,

    GET_AUDIT: (dataroomId) => `/dataroom/${dataroomId}/audit`,
    PREPARE_AUDIT: (dataroomId) => `/dataroom/${dataroomId}/prepare-audit`,
    GET_WORKFLOW: (dataroomId) => `dataroom/${dataroomId}/workflow`,
    UPDATE_PRIORITY_DESCRIPTION: (dataroomId, controlId) => `dataroom/${dataroomId}/controls/${controlId}/priority_description`,
    UPDATE_PRIORITY: (dataroomId, controlId) => `/dataroom/${dataroomId}/controls/${controlId}/priority`,
    UPDATE_STATUS: (dataroomId, controlId) => `/dataroom/${dataroomId}/controls/${controlId}/status`,
    UPDATE_NAME: (dataroomId, controlId) => `/dataroom/${dataroomId}/controls/${controlId}/name`,
    UPDATE_DESCRIPTION: (dataroomId, controlId) => `/dataroom/${dataroomId}/controls/${controlId}/description`,
    UPDATE_STEPS: (dataroomId, controlId) => `/dataroom/${dataroomId}/controls/${controlId}/steps`,
    UPDATE_RESULTS: (dataroomId, controlId) => `/dataroom/${dataroomId}/controls/${controlId}/results`,
};
