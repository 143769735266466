import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { formatDate } from '../../utils/Date';

function IRDetails({ ir }) {
    function getStatusVariant(status) {
        switch (status) {
            case 'Completed':
                return "success";
            case 'Overdue':
            case 'Rejected':
                return "danger";
            default:
                return "primary";
        }
    }

    return (
        <div className="ir-details">
            <Row className="mb-3">
                <Col>
                    <h2 className="ir-title">
                        <i className="bi bi-file-text me-2"></i>
                        {ir.title}
                    </h2>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <Badge bg={getStatusVariant(ir.status)} className="status-badge">{ir.status}</Badge>
                </Col>
            </Row>
            
            <Row className="mb-4">
                <Col xs={12}>
                    <h6>Description</h6>
                    <p style={{ whiteSpace: 'pre-line' }}>{ir.description}</p>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={6}>
                    <h6>Request Details</h6>
                    <div className="detail-item">
                        <i className="bi bi-folder icon"></i>
                        <div>
                            <small>Folder</small>
                            <p>{ir.folder_name}</p>
                        </div>
                    </div>
                    <div className="detail-item">
                        <i className="bi bi-calendar-event icon"></i>
                        <div>
                            <small>Deadline</small>
                            <p>{formatDate(ir.deadline)}</p>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <h6>People</h6>
                    <div className="detail-item">
                        <i className="bi bi-person-circle icon"></i>
                        <div>
                            <small>Assignee</small>
                            <p>{ir.assignee_name || 'Unassigned'}</p>
                        </div>
                    </div>
                    <div className="detail-item">
                        <i className="bi bi-person-plus icon"></i>
                        <div>
                            <small>Created by</small>
                            <p>{ir.creator}</p>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <h6>Dates</h6>
                </Col>
                <Col xs={6}>
                    <div className="detail-item">
                        <i className="bi bi-clock-history icon"></i>
                        <div>
                            <small>Created</small>
                            <p>{formatDate(ir.createdAt)}</p>
                        </div>
                    </div>
                </Col>
                <Col xs={6}>
                    <div className="detail-item">
                        <i className="bi bi-clock icon"></i>
                        <div>
                            <small>Last updated</small>
                            <p>{formatDate(ir.lastUpdate)}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default IRDetails;