import React, { useState, useRef, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import './StatusDropdown.css';

function StatusDropdown({ item, handleStatusChange, statuses, statusLoading, statusError }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const toggleRef = useRef(null);
    const menuRef = useRef(null);

    function getStatusVariant(status) {
        switch (status) {
            case 'Completed':
                return "success";
            case 'Overdue':
            case 'Rejected':
                return "danger";
            case 'In Progress':
                return "primary";
            case 'Pending':
                return "warning";
            default:
                return "secondary";
        }
    }

    const handleDropdownSelect = (selectedStatusName) => {
        handleStatusChange(item, selectedStatusName);
        setDropdownOpen(false);
    };

    const isUpdateAllowed = item && item.permissions && item.permissions.Update;

    useEffect(() => {
        if (toggleRef.current && dropdownOpen) {
            const rect = toggleRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX
            });
        }
    }, [dropdownOpen]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target) &&
                toggleRef.current && !toggleRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const renderDropdownMenu = () => {
        if (!isUpdateAllowed || !dropdownOpen) return null;

        return createPortal(
            <div 
                ref={menuRef}
                className="status-dropdown-menu"
                style={{
                    position: 'fixed',
                    top: `${dropdownPosition.top}px`,
                    left: `${dropdownPosition.left}px`,
                }}
            >
                {statuses && statuses.map((status) => (
                    <Dropdown.Item 
                        key={status.name}
                        onClick={() => handleDropdownSelect(status.name)}
                        className={`dropdown-item status-${getStatusVariant(status.name)}`}
                    >
                        {status.name}
                    </Dropdown.Item>
                ))}
            </div>,
            document.body
        );
    };

    if (statusLoading) return <Dropdown.Toggle className="status-dropdown-toggle status-loading">Chargement...</Dropdown.Toggle>;
    if (statusError) return <Dropdown.Toggle className="status-dropdown-toggle status-error">Erreur</Dropdown.Toggle>;

    return (
        <Dropdown show={dropdownOpen} onToggle={(isOpen) => setDropdownOpen(isOpen)}>
            <Dropdown.Toggle
                ref={toggleRef}
                variant={getStatusVariant(item.status)}
                id="dropdown-status"
                className={`status-dropdown-toggle status-${getStatusVariant(item.status)}`}
            >
                {item.status}
            </Dropdown.Toggle>
            {renderDropdownMenu()}
        </Dropdown>
    );
}

export default StatusDropdown;