import { useState, useEffect } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useDataroomUsers = (token, dataroom_id) => {
    const [dataroomUsers, setDataroomUsers] = useState([]);
    const [errorDataroomUsers, setErrorDataroomUsers] = useState(null);

    useEffect(() => {
        if (!token) return;
    
        const loadUsers = async () => {
            if (!token) return;
    
            try {
                const response = await api.get(API_ROUTES.USERS_BY_DATAROOM(dataroom_id));
                setDataroomUsers(response.data);
                setErrorDataroomUsers(null);
            } catch (error) {
                setErrorDataroomUsers("There was an error fetching the users");
            } 
        };
    
        loadUsers();
      }, [dataroom_id, token]);


    return { dataroomUsers, errorDataroomUsers };
};
