import React from 'react';
import NewRequestForm from './NewRequestForm';

function AddTask({ handleAddRequest, permissions, setModalState }) {
    const hasAddPermissionInAnyCategory = () => {
        if (!permissions || !permissions.categories) {
            return false;
        }

        for (const categoryPermissions of Object.values(permissions.categories)) {
            if (categoryPermissions["IR"] && categoryPermissions["IR"].includes("Create")) {
                return true;
            }
        }

        return false;
    };

    if (!hasAddPermissionInAnyCategory()) {
        return null;
    }

    const handleAddTaskClick = () => {
        setModalState({
            title: "Create new task",
            content: <NewRequestForm onSubmit={handleAddRequest} item={null} />,
            isOpen: true,
            isFullscreen: false,
            isNoPadding: false
        });
    };

    return (
        <button className="btn-add-task" onClick={handleAddTaskClick}>
            <i className="bi bi-plus-square icon-spacing"></i> Create a task
        </button>
    );
}

export default AddTask;