import React from 'react';

const DocumentItem = ({ 
    name, 
    icon, 
    metadata, 
    actions, 
    onClick 
}) => (
    <div className="dm-document-item">
        <div className="dm-info">
            <span 
                className="dm-document-name" 
                onClick={onClick}
            >
                <i className={`bi ${icon} me-2`}></i>
                {name}
            </span>
            {metadata && (
                <small className="dm-info-text">
                    {metadata}
                </small>
            )}
        </div>
        <div className="dm-actions">
            {actions}
        </div>
    </div>
);

export default DocumentItem;