import React, { useState, useCallback } from 'react';
import RequestedDocuments from './RequestedDocuments';
import AssociatedDocuments from './AssociatedDocuments';
import RequestModal from './RequestModal';
import './DocumentManager.css';

function DocumentManager({ 
    procedure, 
    onFileOpen, 
    onRequestDocument, 
    onDeleteMissingDocument,
    onAddMissingDocument,
    onDeleteAssociatedDocument = () => {}
}) {
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [requestType, setRequestType] = useState(null);
    const [criteria, setCriteria] = useState('');
    const [directRequest, setDirectRequest] = useState('');
    const [processingRequests, setProcessingRequests] = useState(new Set());
    const [aiError, setAiError] = useState(null);

    const handleAddFileRequest = useCallback(async (controlId, request) => {
        await onAddMissingDocument(controlId, request.request_message);
    }, [onAddMissingDocument]);

    const analyzeWithAI = useCallback(async (criteria) => {
        const tempId = `temp-${Date.now()}`;
        setProcessingRequests(prev => new Set(prev).add(tempId));
        setAiError(null);

        try {
            const response = await fetch('/api/ai/analyze-criteria', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    controlId: procedure.dataroom_control_id,
                    criteria: criteria
                })
            });

            if (!response.ok) {
                throw new Error('Failed to analyze criteria');
            }

            const suggestions = await response.json();
            
            for (const suggestion of suggestions) {
                await handleAddFileRequest(procedure.dataroom_control_id, {
                    request_message: suggestion.message,
                    is_request: true,
                    ai_generated: true,
                    original_criteria: criteria
                });
            }
        } catch (error) {
            console.error('AI Analysis error:', error);
            setAiError('Failed to analyze criteria. Please try again or use direct request.');
        } finally {
            setProcessingRequests(prev => {
                const newSet = new Set(prev);
                newSet.delete(tempId);
                return newSet;
            });
        }
    }, [procedure.dataroom_control_id, handleAddFileRequest]);

    const handleRequestSubmit = async () => {
        if (requestType === 'ai') {
            await analyzeWithAI(criteria);
        } else {
            await handleAddFileRequest(procedure.dataroom_control_id, {
                request_message: directRequest,
                is_request: true,
                ai_generated: false
            });
        }
        resetModal();
    };

    const resetModal = () => {
        setShowRequestModal(false);
        setRequestType(null);
        setCriteria('');
        setDirectRequest('');
        setAiError(null);
    };

    const requestedDocs = procedure.files.filter(f => f.is_requested && f.file === null);
    const associatedDocs = procedure.files.filter(f => f.file !== null);

    return (
        <div className="dm-container">
            <RequestedDocuments
                documents={requestedDocs}
                onRequestDocument={onRequestDocument}
                onDeleteRequest={onDeleteMissingDocument}
                processingRequests={processingRequests}
                onAddClick={() => setShowRequestModal(true)}
                controlId={procedure.dataroom_control_id}
            />
            
            <AssociatedDocuments
                documents={associatedDocs}
                onFileOpen={onFileOpen}
                onDeleteFile={onDeleteAssociatedDocument}
                controlId={procedure.dataroom_control_id}
            />

            <RequestModal
                show={showRequestModal}
                onHide={resetModal}
                requestType={requestType}
                setRequestType={setRequestType}
                criteria={criteria}
                setCriteria={setCriteria}
                directRequest={directRequest}
                setDirectRequest={setDirectRequest}
                onSubmit={handleRequestSubmit}
                processingRequests={processingRequests}
                aiError={aiError}
            />
        </div>
    );
}

export default DocumentManager;