import { useState, useCallback } from 'react';
import api from '../../services/api';
import { API_ROUTES } from '../../services/apiRoutes';

export const useCreateFile = (token, dataroomId) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const createFile = useCallback(async (file, folderId, progressCallback) => {
        console.log('createFile called with:', { file, folderId, dataroomId });
        setIsLoading(true);
        setError(null);

        const isValidFileType = (file) => {
            const allowedExtensions = /(\.pdf|\.txt|\.doc|\.docx|\.pptx|\.ppt|\.xls|\.xlsx)$/i;
            return allowedExtensions.test(file.name.toLowerCase());
        };

        if (!isValidFileType(file)) {
            console.error('Invalid file type:', file.name);
            setError(new Error(`Invalid file type: ${file.name}`));
            setIsLoading(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('folder_id', folderId);

            console.log('FormData created:', formData);

            // Uploader le fichier
            progressCallback(0, 'Uploading');
            const uploadResponse = await api.post(API_ROUTES.UPLOAD_FILES(dataroomId), formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 10) / progressEvent.total);
                    progressCallback(percentCompleted, 'Uploading');
                }
            });

            const { file_id, file_key } = uploadResponse.data;
            progressCallback(10, 'Uploading');

            // Créer l'URL pour l'EventSource avec le token et le file_id
            const eventSourceUrl = `${process.env.REACT_APP_API_URL}/dataroom/${dataroomId}/process/${file_id}?token=${encodeURIComponent(token)}`;
            console.log('API route for processing:', eventSourceUrl);

            // Créer une nouvelle Promise pour gérer l'EventSource
            return new Promise((resolve, reject) => {
                console.log('Creating EventSource...');
                const eventSource = new EventSource(eventSourceUrl);
                
                console.log('EventSource created. Adding event listeners...');
            
                eventSource.onopen = (event) => {
                    console.log('EventSource connection opened:', event);
                };
            
                eventSource.onmessage = (event) => {
                    console.log('Raw SSE data received:', event.data);
                    try {
                        const data = JSON.parse(event.data);
                        console.log('Parsed SSE data:', data);
            
                        if (data.error) {
                            console.error('Error in SSE data:', data.error);
                            eventSource.close();
                            setError(new Error(data.error));
                            reject(new Error(data.error));
                        } else {
                            console.log('Progress update:', data.progress, data.stage);
                            progressCallback(data.progress, data.stage);

                            if (data.stage === 'Completed') {
                                eventSource.close();
                                setIsLoading(false);
                                resolve({ file_id, file_key });
                            }
                        }
                    } catch (error) {
                        console.error('Error parsing SSE data:', error);
                        eventSource.close();
                        setError(new Error('Error parsing SSE data'));
                        reject(new Error('Error parsing SSE data'));
                    }
                };
            
                eventSource.onerror = (err) => {
                    console.error('SSE Error:', err);
                    console.log('EventSource readyState:', eventSource.readyState);
                    eventSource.close();
                    setError(new Error('Error during file processing'));
                    reject(new Error('Error during file processing'));
                };
            
                console.log('All event listeners added to EventSource');
            });

        } catch (error) {
            console.error('Error in createFile:', error);
            setError(error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [token, dataroomId]);

    return { createFile, isLoading, error };
};