import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import DocumentManager from './documents/DocumentManager';
import './ProcedureDetailsRight.css';

import { formatDate } from '../../utils/Date';

function ProcedureDetailsRight({ 
    procedure,
    onStartProcedure,
    onFileOpen,
    onRequestDocument,
    onDeleteMissingDocument,
    onAddMissingDocument,
    supervisor,
    auditor,
    onUpdatePriorityDescription,
    onUpdatePriority,
    onUpdateStatus
}) {

    const [isEditing, setIsEditing] = useState(false);
    const [editedDescription, setEditedDescription] = useState(procedure.priority_description || '');

    const [isEditingPriority, setIsEditingPriority] = useState(false);
    const [editedPriority, setEditedPriority] = useState(procedure.priority);

    const [currentStatus, setCurrentStatus] = useState(procedure.status);


    const priorityOptions = ['Very Low', 'Low', 'Medium', 'High', 'Critical'];

    const handlePriorityEditClick = () => {
        setIsEditingPriority(true);
        setEditedPriority(procedure.priority);
    };

    const handlePrioritySave = async () => {
        if (await onUpdatePriority(editedPriority)) {
            setIsEditingPriority(false);
        }
    };

    const handlePriorityCancel = () => {
        setIsEditingPriority(false);
        setEditedPriority(procedure.priority);
    };
    
    const handleEditClick = () => {
        setIsEditing(true);
        setEditedDescription(procedure.priority_description || '');
    };

    const handleSave = async () => {
        if (await onUpdatePriorityDescription(editedDescription)) {
            setIsEditing(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedDescription(procedure.priority_description || '');
    };

    const handleStatusChange = async (newStatus) => {
        const success = await onUpdateStatus(procedure.dataroom_control_id, newStatus);
        if (success) {
            setCurrentStatus(newStatus);
        }
    };

    const getStatusInfo = (status) => {
        const statusStyles = {
            'completed': { color: '#27ae60', icon: 'check-circle-fill' },
            'in progress': { color: '#3498db', icon: 'play-circle-fill' },
            'review': { color: '#f39c12', icon: 'eye-fill' },
            'ready': { color: '#2980b9', icon: 'arrow-right-circle-fill' },
            'missing documents': { color: '#e74c3c', icon: 'exclamation-circle-fill' }
        };
        return statusStyles[status.toLowerCase()] || { color: '#95a5a6', icon: 'question-circle-fill' };
    };

    const getPreviousStatus = (currentStatus) => {
        const statusFlow = {
            'Completed': 'Review',
            'Review': 'In Progress',
            'In Progress': 'Ready',
            'Ready': null,
            'Missing Documents': null
        };
        return statusFlow[currentStatus] || null;
    };

    const getNextStatusInfo = (currentStatus) => {
        const statusFlow = {
            'ready': { 
                next: 'In Progress', 
                text: 'Start Procedure',
                icon: 'play-fill'
            },
            'in progress': { 
                next: 'Review', 
                text: 'Send to Review',
                icon: 'eye-fill'
            },
            'review': { 
                next: 'Completed', 
                text: 'Complete Review',
                icon: 'check2-circle'
            },
            'completed': { 
                next: 'In Progress', 
                text: 'Reopen',
                icon: 'arrow-clockwise'
            },
            'missing documents': { 
                next: 'Ready', 
                text: 'Mark as Ready',
                icon: 'check2-square'
            }
        };
        return statusFlow[currentStatus.toLowerCase()] || { next: '', text: '', icon: '' };
    };

    const getPriorityColor = (priority) => {
        const colors = ['#95a5a6', '#3498db', '#f39c12', '#e67e22', '#e74c3c'];
        return colors[priority - 1] || '#95a5a6';
    };

    useEffect(() => {
        setCurrentStatus(procedure.status);
    }, [procedure.status]);

    return (
        <div className="procedure-details-right">

            <div className="document-section">
                <h3 className="section-title-right">Documents</h3>
                <DocumentManager 
                    procedure={procedure}
                    onFileOpen={onFileOpen}
                    onRequestDocument={onRequestDocument}
                    onDeleteMissingDocument={onDeleteMissingDocument}
                    onAddMissingDocument={onAddMissingDocument}
                />
            </div>

            <div className="status-section">
                <h3 className="section-title-right">Priority</h3>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                <div className="info-item">
                        <span className="info-label">
                            Level
                            {!isEditingPriority && (
                                <i 
                                    className="bi bi-pencil-fill ms-2" 
                                    style={{ cursor: 'pointer', fontSize: '0.8em', color: '#6c757d' }}
                                    onClick={handlePriorityEditClick}
                                ></i>
                            )}
                        </span>
                        {isEditingPriority ? (
                            <div className="d-flex flex-column gap-2">
                                <select
                                    className="form-select"
                                    value={editedPriority}
                                    onChange={(e) => setEditedPriority(e.target.value)}
                                >
                                    {priorityOptions.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                                <div className="d-flex gap-2">
                                    <button 
                                        className="btn btn-sm btn-success" 
                                        onClick={handlePrioritySave}
                                    >
                                        <i className="bi bi-check-lg me-1"></i>
                                        Save
                                    </button>
                                    <button 
                                        className="btn btn-sm btn-secondary" 
                                        onClick={handlePriorityCancel}
                                    >
                                        <i className="bi bi-x-lg me-1"></i>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <span className="info-value" style={{ color: getPriorityColor(procedure.priority) }}>
                                <i className="bi bi-flag-fill me-2"></i>
                                {procedure.priority}
                            </span>
                        )}
                    </div>
                    <div className="info-item">
                        <span className="info-label">
                            Description
                            {!isEditing && (
                                <i 
                                    className="bi bi-pencil-fill ms-2" 
                                    style={{ cursor: 'pointer', fontSize: '0.8em', color: '#6c757d' }}
                                    onClick={handleEditClick}
                                ></i>
                            )}
                        </span>
                        {isEditing ? (
                            <div className="d-flex flex-column gap-2">
                                <textarea
                                    className="form-control"
                                    value={editedDescription}
                                    onChange={(e) => setEditedDescription(e.target.value)}
                                    rows="3"
                                />
                                <div className="d-flex gap-2">
                                    <button 
                                        className="btn btn-sm btn-success" 
                                        onClick={handleSave}
                                    >
                                        <i className="bi bi-check-lg me-1"></i>
                                        Save
                                    </button>
                                    <button 
                                        className="btn btn-sm btn-secondary" 
                                        onClick={handleCancel}
                                    >
                                        <i className="bi bi-x-lg me-1"></i>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <span className="info-value">
                                {procedure.priority_description || 'No description provided'}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className="info-section">
                <h3 className="section-title-right">Information</h3>
                <div className="info-grid">
                    <div className="info-item">
                        <span className="info-label">
                            <i className="bi bi-person-badge me-2"></i>
                            Auditor
                        </span>
                        <span className="info-value">{auditor}</span>
                    </div>
                    <div className="info-item">
                        <span className="info-label">
                            <i className="bi bi-person-check me-2"></i>
                            Supervisor
                        </span>
                        <span className="info-value">{supervisor}</span>
                    </div>
                    <div className="info-item">
                        <span className="info-label">
                            <i className="bi bi-calendar-plus me-2"></i>
                            Created On
                        </span>
                        <span className="info-value">{formatDate(procedure.created_at)}</span>
                    </div>
                    <div className="info-item">
                        <span className="info-label">
                            <i className="bi bi-calendar-check me-2"></i>
                            Last Updated
                        </span>
                        <span className="info-value">{formatDate(procedure.updated_at)}</span>
                    </div>
                    <div className="info-item">
                        <span className="info-label">
                            <i className="bi bi-info-circle me-2"></i>
                            Status
                        </span>
                        <div className="d-flex align-items-center gap-2">
                            <span className="info-value" style={{ color: getStatusInfo(currentStatus).color }}>
                                <i className={`bi bi-${getStatusInfo(currentStatus).icon} me-2`}></i>
                                {currentStatus}
                            </span>
                            {getPreviousStatus(currentStatus) && (
                                <Button
                                    onClick={() => handleStatusChange(getPreviousStatus(currentStatus))}
                                    className="btn btn-sm p-0"
                                    style={{
                                        backgroundColor: '#e9ecef',
                                        border: 'none',
                                        width: '24px',
                                        height: '24px',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    title="Return to previous status"
                                >
                                    <i className="bi bi-arrow-left" style={{ fontSize: '0.8rem', color: '#495057' }}></i>
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="info-item" style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        height: '100%'
                    }}>
                        {(() => {
                            const { next, text, icon } = getNextStatusInfo(currentStatus);
                            if (!next) return null;
                            
                            return (
                                <Button
                                    onClick={() => handleStatusChange(next)}
                                    className="btn btn-sm status-action-button w-100"
                                    style={{
                                        backgroundColor: getStatusInfo(next.toLowerCase()).color,
                                        border: 'none',
                                        color: 'white',
                                        padding: '0.375rem 0.75rem'
                                    }}
                                >
                                    <i className={`bi bi-${icon} me-2`}></i>
                                    {text}
                                </Button>
                            );
                        })()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProcedureDetailsRight;
