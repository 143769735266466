import React, { useState, useContext } from 'react';
import { Table } from 'react-bootstrap';
import { useUser } from '../../general/UserProvider';
import { DataRoomContext } from '../DisplayDataRoom';
import LayoutPageHeader from '../../general/LayoutPageHeader';
import GenericModal from '../../general/GenericModal';
import IRRow from './table/IRRow';
import TableHeader from './table/TableHeader';
import AddTask from './AddTask';
import IRDetails from './IRDetails';
import { useCreateInformationRequest } from '../../hooks/ir/useCreateInformationRequest';
import { useDeleteInformationRequest } from '../../hooks/ir/useDeleteInformationRequest';
import { useFetchIRLData } from '../../hooks/ir/useFetchIRLData';
import { useFetchIRStatuses } from '../../hooks/ir/useFetchIRStatuses';
import { useHandleIRStatusChange } from '../../hooks/ir/useHandleIRStatusChange';
import { useSortRequests } from '../../hooks/ir/useSortRequests';
import { useUpdateInformationRequest } from '../../hooks/ir/useUpdateInformationRequest';
import { usePermissions } from '../../hooks/permissions/useUserPermissions';
import './IRL.css';

function IRL() {
    const { token } = useUser();
    const dataroom_id = useContext(DataRoomContext);
    const { permissions } = usePermissions();

    const { requests, loading, error, setRequests } = useFetchIRLData(token, dataroom_id);
    const { statuses, loading: statusLoading, error: statusError } = useFetchIRStatuses(token);
    const createRequest = useCreateInformationRequest(token, setRequests);
    const handleStatusChange = useHandleIRStatusChange({ token, requests, setRequests });
    const { sortedRequests, handleSort, sortField, sortDirection } = useSortRequests(requests);
    const updateRequest = useUpdateInformationRequest(token, requests, setRequests);

    const [modalState, setModalState] = useState({
        title: "",
        isOpen: false,
        content: "",
        fullscreen: false,
        centered: true
    });

    const closeModal = () => {
        setModalState(prevState => ({
            ...prevState,
            isOpen: false
        }));
    };
    const deleteRequest = useDeleteInformationRequest(token, setRequests, closeModal);

    const handleAddRequest = async (requestData) => {
        const requestBody = {
            folder_id: requestData.folder_id,
            dataroom_id: parseInt(dataroom_id, 10),
            title: requestData.title,
            description: requestData.description,
            deadline: requestData.deadline,
            assignee: requestData.assignee
        };

        try {
            await createRequest(dataroom_id, requestBody);
            closeModal();
        } catch (error) {
            console.error("Error in creating request", error);
        }
    };

    const handleUpdateRequest = async (itemToUpdate, originalItem) => {
        if (originalItem.folder_id !== itemToUpdate.folder_id ||
            originalItem.title !== itemToUpdate.title ||
            originalItem.description !== itemToUpdate.description ||
            originalItem.deadline !== itemToUpdate.deadline ||
            originalItem.assignee !== itemToUpdate.assignee ||
            originalItem.status !== itemToUpdate.status_name) {

            await updateRequest(originalItem.irl_id, itemToUpdate);
        } else {
            console.log("No changes detected, skipping update.");
        }
        closeModal();
    };

    async function submitDelete(itemToDelete) {
        await deleteRequest(itemToDelete.irl_id);
    }

    const handleRowClick = (ir) => {
        setModalState({
            title: "Information Request Details",
            isOpen: true,
            content: <IRDetails ir={ir} />,
            fullscreen: true,
            centered: true
        });
    };

    const actionButtons = (
        <AddTask
            handleAddRequest={handleAddRequest}
            permissions={permissions}
            setModalState={setModalState}
        />
    );

    return (
        <LayoutPageHeader title="Questions & Answers" actions={actionButtons}>
            {error && <p className="text-danger">{error}</p>}
            {loading ? (
                <div className="loading-spinner">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="irl-table-wrapper">
                    <div className="irl-table-container">
                        <Table responsive className="irl-table">
                            <TableHeader 
                                sortField={sortField} 
                                sortDirection={sortDirection} 
                                handleSort={handleSort} 
                            />
                            <tbody>
                                {sortedRequests.map((item) => (
                                    <IRRow
                                        key={item.irl_id}
                                        item={item}
                                        handleStatusChange={handleStatusChange}
                                        statuses={statuses}
                                        statusLoading={statusLoading}
                                        statusError={statusError}
                                        setModalState={setModalState}
                                        closeModal={closeModal}
                                        submitDelete={submitDelete}
                                        handleUpdateRequest={handleUpdateRequest}
                                        dataroom_id={dataroom_id}
                                        onRowClick={handleRowClick}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
            <GenericModal
                title={modalState.title}
                show={modalState.isOpen}
                onHide={() => closeModal()}
                fullscreen={modalState.fullscreen}
                centered={modalState.centered}
            >
                {modalState.content}
            </GenericModal>
        </LayoutPageHeader>
    );
}

export default IRL;