import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import DocumentItem from './DocumentItem';
import DocumentSection from './DocumentSection';

const RequestedDocuments = ({
    documents,
    onRequestDocument,
    onDeleteRequest,
    processingRequests,
    onAddClick,
    controlId
}) => {
    const AddButton = (
        <Button 
            variant="light"
            size="sm"
            onClick={onAddClick}
            className="dm-add-btn"
        >
            <i className="bi bi-plus-lg"></i>
        </Button>
    );

    return (
        <DocumentSection title="Requested Documents" action={AddButton}>
            {processingRequests.size > 0 && (
                <div className="dm-processing">
                    <Spinner 
                        animation="border" 
                        size="sm" 
                        className="me-2"
                    />
                    <span>AI is analyzing your criteria...</span>
                </div>
            )}

            {documents.length > 0 ? (
                <div className="dm-list">
                    {documents.map((request) => (
                        <DocumentItem
                            key={request.id}
                            name={request.request_message}
                            icon="bi-file-earmark-excel"
                            metadata={
                                <>
                                    {request.ai_generated && <i className="bi bi-robot me-1"></i>}
                                    Requested by: {request.requested_by_username || 'Unknown'}
                                    {request.requested_at && ` on ${new Date(request.requested_at).toLocaleDateString()}`}
                                </>
                            }
                            actions={
                                <>
                                    <i 
                                        className="bi bi-send dm-action-btn dm-action-btn--send"
                                        onClick={() => onRequestDocument(controlId, request.id)}
                                    ></i>
                                    <i 
                                        className="bi bi-trash dm-action-btn dm-action-btn--delete"
                                        onClick={() => onDeleteRequest(controlId, request.id)}
                                    ></i>
                                </>
                            }
                        />
                    ))}
                </div>
            ) : (
                <p className="dm-empty">No document requests.</p>
            )}
        </DocumentSection>
    );
};

export default RequestedDocuments;