import React from 'react';
import { Modal } from 'react-bootstrap';
import './GenericModal.css';

function GenericModal({ show, onHide, title, children, fullscreen = false, centered = true, noPadding = false }) {
    const modalDialogClass = `generic-modal ${fullscreen ? 'modal-fullscreen' : ''}`;
    const modalBodyClass = `generic-modal-content ${noPadding ? 'no-padding' : ''}`;

    return (
        <Modal 
            show={show} 
            onHide={onHide} 
            fullscreen={fullscreen} 
            centered={centered}
            dialogClassName={modalDialogClass}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title className="generic-modal-title">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={modalBodyClass}>{children}</Modal.Body>
        </Modal>
    );
}

export default GenericModal;